.App {
  min-height: 100vh;
}

.Auth-Layout {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
}

.App-Header {
  position: sticky;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 900;
}

.App-Feedback {
  position: sticky;
  top: 80vh;
  z-index: 900;
  display: none;
}

.App-Body {
  min-height: 80vh;
  padding: 24px 0px;
}
